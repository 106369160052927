.wrapper {
  z-index: 0;
}

.MainParagraph {
  z-index: 5;
  color: red;
  position: absolute;
  top: 5cm;
}

.vid {
  z-index: -5;
  overflow-x: hidden;
}

.video {
  overflow-x: hidden;
}
.pictures {
  z-index: 13;
}
body {
  background: black;
}
