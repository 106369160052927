body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header {
  background: #000000;
  height: 95px;
  position: relative;
}
.logo {
  width: 200px;
  height: auto;
  position: absolute;
  left: 10cm;
  bottom: 0px;
}

.nav-bar {
  position: absolute;
  left: 35%;
  bottom: -2px;
  font-size: 17px;
  letter-spacing: 4px;
  text-align: center;
}

.home {
  display: inline;
  margin: 30px;
  text-decoration: none;
  text-transform: uppercase;
}
.contact {
  display: inline;
  margin: 30px;
}
.project {
  display: inline;
  margin: 30px;
}
.moreinfo {
  display: inline;
  margin: 30px;
}

a {
  color: #bbbdbb;
  text-decoration: none;
  text-transform: uppercase;
}
a:hover {
  color: red;
  font-size: 30px;
  font-size-adjust: 20px;
}

.BodyMain {
  background: blue;
}

.wrapper {
  z-index: 0;
}

.MainParagraph {
  z-index: 5;
  color: red;
  position: absolute;
  top: 5cm;
}

.vid {
  z-index: -5;
  overflow-x: hidden;
}

.video {
  overflow-x: hidden;
}
.pictures {
  z-index: 13;
}
body {
  background: black;
}

#myContainer {
  width: 400px;
  height: 400px;
  position: relative;
  background: yellow;
}

#myAnimation {
  width: 50px;
  height: 50px;
  position: absolute;
  background: red;
}

.container {
  z-index: 11;
  opacity: 0.9;
  position: relative;
  background: rgb(163, 70, 163);
  width: 75%;
  height: 300px;
  left: 15%;
  margin: 25px;
  position: absolute;
}

.paragraph {
  width: 20cm;
  background-color: cyan;
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
}

.pictures {
  width: 300px;
  height: 200px;
  float: left;
  margin-left: 59px;
  z-index: 13;
}

.title {
  text-indent: 1cm;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 0.5cm;
  color: green;
}

#test1 {
  position: relative;
  bottom: 0cm;
  float: top;
}
#test2 {
  position: relative;
  bottom: -10cm;
  float: bottom;
}
#test3 {
  position: relative;
  bottom: -20cm;
  float: bottom;
}

#test4 {
  position: relative;
  bottom: -30cm;
  float: bottom;
}
.master {
  background: black;
}

#hero {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))),
    url(/static/media/Sunset.4e12c033.jpg);
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/Sunset.4e12c033.jpg);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/Sunset.4e12c033.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: -10;
}
.resume-container {
  background: peru;
  color: whitesmoke;
  border-radius: 5px;
  position: absolute;

  padding-top: 3px;
  padding-left: 5px;
  margin-top: -10cm;
  -webkit-text-emphasis-position: under;
          text-emphasis-position: under;
  margin-left: 20%;
  margin-right: 60%;
  z-index: 10;
}
.anotherflash {
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
  background-color: peru;
  color: red;
  font-weight: 1000;
  z-index: 10;
}

@-webkit-keyframes blinker {
  40% {
    opacity: 0;
  }
}

@keyframes blinker {
  40% {
    opacity: 0;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

h1 {
  font-size: 3rem;
  font-family: cursive;
}

.Name {
  color: red;
  position: absolute;
  right: 20%;
  top: 50%;
}

.Intro {
  position: relative;
  margin: 20%;
  margin-top: 2px;
  top: 0px;
  text-align: justify;
  -webkit-text-fill-color: #eeeaea;
  font-display: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #2c001e;
  padding: 10px;
  border-radius: 20px;
}

.flashy {
  -webkit-animation: blinker 2s linear infinite;
          animation: blinker 2s linear infinite;
  background-color: red;
  font-weight: 1000;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
b {
  font-weight: 600;
}
.special {
  font-weight: 1000;
}

