* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

h1 {
  font-size: 3rem;
  font-family: cursive;
}

.Name {
  color: red;
  position: absolute;
  right: 20%;
  top: 50%;
}

.Intro {
  position: relative;
  margin: 20%;
  margin-top: 2px;
  top: 0px;
  text-align: justify;
  -webkit-text-fill-color: #eeeaea;
  font-display: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #2c001e;
  padding: 10px;
  border-radius: 20px;
}

.flashy {
  animation: blinker 2s linear infinite;
  background-color: red;
  font-weight: 1000;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
b {
  font-weight: 600;
}
.special {
  font-weight: 1000;
}
