#myContainer {
  width: 400px;
  height: 400px;
  position: relative;
  background: yellow;
}

#myAnimation {
  width: 50px;
  height: 50px;
  position: absolute;
  background: red;
}

.container {
  z-index: 11;
  opacity: 0.9;
  position: relative;
  background: rgb(163, 70, 163);
  width: 75%;
  height: 300px;
  left: 15%;
  margin: 25px;
  position: absolute;
}

.paragraph {
  width: 20cm;
  background-color: cyan;
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
}

.pictures {
  width: 300px;
  height: 200px;
  float: left;
  margin-left: 59px;
  z-index: 13;
}

.title {
  text-indent: 1cm;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 0.5cm;
  color: green;
}

#test1 {
  position: relative;
  bottom: 0cm;
  float: top;
}
#test2 {
  position: relative;
  bottom: -10cm;
  float: bottom;
}
#test3 {
  position: relative;
  bottom: -20cm;
  float: bottom;
}

#test4 {
  position: relative;
  bottom: -30cm;
  float: bottom;
}
.master {
  background: black;
}
